import {useEffect, useMemo, useRef} from 'react';

interface ICheatA<TKey extends string = string> {
    key: TKey;
    cb: (key: TKey) => void;
}

interface ICheatB<
    TKey extends string = string,
    TValues extends string[] = string[]
> {
    key: string;
    values: TValues;
    cb: (key: TKey, value: TValues[number]) => void;
}

type TUnknownCheat = ICheatA | ICheatB;

type TCheatsDescriptors = Record<string, string[] | void>;

type TCheat<TK extends string, TV = void> = TV extends string[]
    ? {
          key: TK;
          values: TV;
          cb: (key: TK, value: TV[number]) => void;
      }
    : {
          key: TK;
          cb: (key: TK) => void;
      };

type TCheats<TD extends TCheatsDescriptors> = {
    [k in keyof TD]: k extends string ? TCheat<k, TD[k]> : never;
}[keyof TD][];

interface IStore {
    cheats: TUnknownCheat[];
    maxKeyLength: number;
    maxValueLength: number;
}

function useCheats<TD extends TCheatsDescriptors>(cheats: TCheats<TD>): void {
    const keyStrokes = useRef<string>('');
    const valueStrokes = useRef<string>('');

    const match = useRef<ICheatB | null>(null);

    const store = useMemo<IStore>(() => {
        const output: IStore = {
            cheats: [],
            maxKeyLength: 0,
            maxValueLength: 0
        };

        for (const cheat of cheats) {
            const item: TUnknownCheat = {
                ...cheat,
                key: cheat.key.toLowerCase()
            };

            output.maxKeyLength = Math.max(
                output.maxKeyLength,
                item.key.length
            );

            if ('values' in item) {
                item.values = item.values.map((value) => {
                    output.maxValueLength = Math.max(
                        output.maxValueLength,
                        value.length
                    );

                    return value.toLowerCase();
                });
            }

            output.cheats.push(item);
        }

        return output;
    }, [cheats]);

    useEffect(() => {
        const onKeydownHandler = (event: KeyboardEvent): void => {
            if (!event.key || event.key.length !== 1) {
                keyStrokes.current = '';
                match.current = null;
                return;
            }

            if (match.current) {
                valueStrokes.current += event.key.toLowerCase();

                const values = match.current.values;

                for (const value of values) {
                    if (valueStrokes.current.endsWith(value)) {
                        match.current.cb(match.current.key, value);

                        valueStrokes.current = '';
                        match.current = null;

                        return;
                    }
                }

                if (valueStrokes.current.length >= store.maxValueLength) {
                    valueStrokes.current = '';
                    match.current = null;
                }
            } else {
                const d = keyStrokes.current.length - store.maxKeyLength + 1;

                if (d > 0) {
                    keyStrokes.current = keyStrokes.current.slice(d);
                }

                keyStrokes.current += event.key.toLowerCase();

                for (const cheat of store.cheats) {
                    if (keyStrokes.current.endsWith(cheat.key)) {
                        if ('values' in cheat) {
                            match.current = cheat;
                        } else {
                            cheat.cb(cheat.key);

                            keyStrokes.current = '';
                        }

                        return;
                    }
                }
            }
        };

        window.addEventListener('keydown', onKeydownHandler);

        return () => {
            window.removeEventListener('keydown', onKeydownHandler);
        };
    }, [store]);
}

export type {TCheats, TCheatsDescriptors};
export {useCheats};

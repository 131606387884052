import type {TLogLevel} from '@mcal/core';
import {URLUtils, logger} from '@mcal/core';
import {appThunks, notificationsActions, objectURLs} from '@mcal/core-react';
import {ApplicationManager, Cheats, browserConsole} from '@mcal/core-react-web';
import {StrictMode} from 'react';
import {createRoot} from 'react-dom/client';
import type {Metric} from 'web-vitals';
import {App} from './app.js';
import {reportWebVitals} from './report-web-vitals.js';
import {store} from './store/store.js';
import 'mapbox-gl/dist/mapbox-gl.css';

logger.setLogLevel(process.env.LOG_LEVEL || 'none');
logger.setConsole(browserConsole);

objectURLs.setImplementation({
    create: (obj) => URL.createObjectURL(obj),
    revoke: (url) => URL.revokeObjectURL(url),
    prefix: 'blob:'
});

const log = logger.withCaller('app-react-web-dashboards');

const root = createRoot(document.getElementById('root') as HTMLElement);

const render = (): void => {
    log.debug('RENDERING APP')();

    const LOCIZE_PROJECT_ID = process.env.LOCIZE_PROJECT_ID;

    if (!LOCIZE_PROJECT_ID) {
        throw new Error('MISSING ENV VARIABLE "LOCIZE_PROJECT_ID"');
    }

    const LOCIZE_API_KEY = process.env.LOCIZE_API_KEY;

    void store.dispatch(
        appThunks.loadApp({
            i18nConfig: {
                options: {
                    debug: process.env.ENV === 'dev'
                },
                backend: {
                    LOCIZE_PROJECT_ID,
                    LOCIZE_API_KEY
                }
            }
        })
    );

    root.render(
        <StrictMode>
            {process.env.ENV === 'dev' ? (
                <Cheats<{
                    whoIsYourCreator: void;
                    whoAreYou: void;
                    setLogLevel: TLogLevel[];
                    closeIt: void;
                }>
                    cheats={[
                        {
                            key: 'whoIsYourCreator',
                            cb: (): void => {
                                void store.dispatch(
                                    notificationsActions.send({
                                        title: 'My Creator',
                                        message: 'My creator is Codistica'
                                    })
                                );
                            }
                        },
                        {
                            key: 'whoAreYou',
                            cb: (): void => {
                                void store.dispatch(
                                    notificationsActions.send({
                                        title: 'Who Am I',
                                        message: 'I am @mcal/core-react-web'
                                    })
                                );
                            }
                        },
                        {
                            key: 'setLogLevel',
                            values: [
                                'none',
                                'fatal',
                                'error',
                                'info',
                                'warning',
                                'debug'
                            ],
                            cb: (_, value): void => {
                                logger.setLogLevel(value);

                                void store.dispatch(
                                    notificationsActions.send({
                                        title: 'Log Level Changed',
                                        message: `Log level set to ${value}`
                                    })
                                );
                            }
                        },
                        {
                            key: 'closeIt',
                            cb: (): void => {
                                localStorage.setItem(
                                    'keepSidenavClosed',
                                    'true'
                                );
                            }
                        }
                    ]}
                />
            ) : null}

            <ApplicationManager store={store}>
                <App />
            </ApplicationManager>
        </StrictMode>
    );
};

if (process.env.MSW === 'true') {
    log.debug('API MOCKS ENABLED')();

    void import('@mcal/core-react-web/dev')
        .then(({msw}) => {
            msw.setup();

            return msw.start({
                serviceWorker: {
                    url: URLUtils.join(
                        process.env.PUBLIC_URL || '/',
                        'mock-service-worker.js'
                    )
                }
            });
        })
        .then(render)
        .catch((reason) => log.error(reason)());
} else {
    render();
}

if (process.env.WEB_VITALS === 'true') {
    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals((metric: Metric) => log.debug(metric)());
}
